* {
    padding: 0;
    margin: 0;
}

.login-main-body {
    color: #FFF;
    border: 0;
    height: 100%;
    margin: 0;
    display: flex !important;
    // padding: 120px 0;
    position: relative;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    background-image: url('../assets/images/login-bg.jpeg');

    &::before {
        background-color: #00000080;
    }

    &::before,
    &::after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        z-index: 2;
        position: absolute;
    }

    .login-children {
        z-index: 4;
    }

    .login-icon {
        height: 50px;
        width: 50px;
        // padding: 20px;
        fill: #FFF;
        // background-color: #FFF;
        // border-radius: 50%;
        // fill: blue;
    }

    .login-card {
        min-width: 25vw;
        padding: 20px;
        overflow: visible;

        @media screen and (max-width : 992px) {
            padding: 20px 0px;
        }
    }
}

.center-align {
    text-align: center !important;
}

.white-text {
    color: #FFF;
}

.edit-profile {
    background-image: none !important;
    justify-content: space-between;
    align-items: flex-start;

    &::before {
        background-color: none !important;
    }

    &::before,
    &::after {
        top: 0;
        left: 0;
        width: 0%;
        height: 0%;
        display: block;
        z-index: 0;
        position: relative;
    }

    .large-form {
        width: 60%;
        margin-right: 5%;
    }

    @media screen and (max-width : 992px) {
        flex-direction: column;

        .large-form {
            width: auto;
            margin-right: auto;
        }
    }
}

.no-spacing{
    margin: 0 !important;
    // padding: 0 !important;
}