@import './themeColors';

a {
    text-decoration: none;
    color: inherit;
}

.sidebar-bg {
    background-image: url('../assets/images/login-bg.jpeg');
    background-size: cover;
    background-position: center center;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    position: absolute;
    background-size: cover;
    background-position: center center;

    &::before {
        // background-color: #000;
        // opacity: 0.5;
        background-color: #00000080;
        // opacity: 0.5;
    }

    &::before,
    &::after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        z-index: 2;
        position: absolute;
    }
}

.sidebar-content {
    z-index: 999;
}

.card-main {
    overflow: visible !important;
    padding: 20px 0px;
}

main {
    margin-top: 45px;
}

.cards--box {
    padding: 15px;
    margin-bottom: 20px;
    // background-color: #ffffff;
    border-radius: 10px;
    // box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
}

.dashboard--cards {

    &:nth-child(4n+1) {
        .card--icon {
            background: linear-gradient(60deg, #ffa726, #fb8c00);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4);
        }
    }

    &:nth-child(4n+2) {
        .card--icon {
            background: linear-gradient(60deg, #26c6da, #00acc1);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 172, 193, .4);
        }
    }

    &:nth-child(4n+3) {
        .card--icon {
            background: linear-gradient(60deg, #263238, #212121);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(33, 33, 33, .4);
        }
    }

    &:nth-child(4n+4) {
        .card--icon {
            background: linear-gradient(60deg, #66bb6a, #43a047);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(76, 175, 80, .4);
        }
    }

    @media screen and (min-width : 992px) {
        max-width: 28% !important;
        margin-left: 4% !important;
    }

    @media screen and (max-width : 992px) {
        margin: 20px 0 !important;
    }
}

.card-box {

    overflow: visible !important;
    padding: 10px;
    min-height: 25%;
    margin-bottom: 50px;

    .card-main-content {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 10px;
        @media screen and (max-width : 992px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .card--content{
                margin-top: 20px;
            }
        }
    }

    .card--icon {
        margin-top: -50px;

        // background: linear-gradient(60deg, #ffa726, #fb8c00);
        // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4);

        // &.card-1, &.card-5 {
        //     background: linear-gradient(60deg, #26c6da, #00acc1);
        //     box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 172, 193, .4);
        // }

        // &.card-2, &.card-6 {
        //     background: linear-gradient(60deg, #263238, #212121);
        //     box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(33, 33, 33, .4);
        // }

        // &.card-3, &.card-7 {
        //     background: linear-gradient(60deg, #66bb6a, #43a047);
        //     box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(76, 175, 80, .4);
        // }

        padding: 5px;
        height: 90px;
        width: 90px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width : 992px) {
            height: 70px;
            width: 70px;
        }
        svg {
            height: 40px;
            width: 40px;
            fill: #ffffff;
        }
    }

    .card--content {
        .card--title {
            font-size: 1.6rem;
            color: #333333;
            font-weight: 500;
        }

        .card--subtitle {
            font-size: 1rem;
            color: $light-text-color;
        }
    }

    .card-subbody {
        font-size: 0.9rem;
        text-align: left;
        font-weight: 100;
        padding-top: 10px;
        color: $light-text-color;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: inherit;

        svg {
            margin-right: 10px;
        }
    }
}

.search-input-extra-margin{
    @media screen and (max-width : 992px) {
        margin-top: 10px; 
    }
}

.evenly-spaced {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.responsive-row-search{
    @media screen and (max-width : 992px) {
        flex-direction: column !important;  
    }
}

.evenly-spaced-button{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    button{
        margin: 5px 0;
    }
}

.spaced-apart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row; 
    @media screen and (max-width : 992px) {
        flex-direction: column; 
    }
}

.row-dir {
    flex-direction: row;
}

.col-dir {
    flex-direction: column;
}

.svg-palceholder {
    max-width: 50vw;
    height: auto;
}

.empty-txt {
    margin: 10px auto;
}

.table--top--header {
    padding-bottom: 10px;
    border-radius: 7px;
    // margin-top: -20px;
    // color: #fff;
    margin-bottom: 20px;
    /* background-color: #7fffd4; */
    margin: 0 20px 20px 20px;
    z-index: 999;
    background: linear-gradient(60deg, #006CE7, #a6d4fa);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(96, 219, 241, 0.4);
    transform: translateY(-20px);
    padding-top: 10px;
    padding-right: 10px !important;

    h5 {
        font-weight: 400 !important;
        color: white;
    }

    h3 {
        color: white;
    }
}

.center-align {
    text-align: center;
}

.link-tag {
    color: #1976d2;
    text-decoration: none;
    transition: all 0.3s;
    text-align: left;
    &:hover {
        text-decoration: underline;
    }
}

.date-picker-component {
    text-align: right;
    padding-bottom: 20px;
}

.fab-bottom {
    display: flex;
    justify-content: space-around;
    // position: fixed;
    // top: 100px;
    // right: 10px;
    // width: 80%;
    margin-bottom: 40px;
}

.date-filter-ui {
    @media screen and (min-width : 992px) {
        margin-bottom: 50px !important;
    }
    margin-top: 0px !important;
}

.extra-bottom-margin {
    margin-bottom: 20px !important;
}

.top-linear-progress {
    transform: translateY(-60px);
}

.form-icon {
    padding: 20px;
    background: linear-gradient(60deg, #26c6da, #00acc1);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 172, 193, .4);

    svg {
        height: 30px;
        width: 30px;
        fill: white;
    }
}

.MuiChip-label {
    white-space: pre-wrap !important;
}

@media screen and (max-width : 992px) {
    .large-form {
        margin-bottom: 50px
    }
}

.no-scroll{
    width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: visible !important;
} 

.no-top-margin{
    padding-top: 24px !important;
}

.left-align{
    text-align: left;
}

.rdrCalendarWrapper{
    @media screen and (max-width : 992px) {
        width: 65% !important;
    }
}

.rdrDefinedRangesWrapper{
    @media screen and (max-width : 992px) {
        width: 35% !important;
    }
}

.rdrNextPrevButton{
    @media screen and (max-width : 992px) {
        margin: 0px !important;
    }
}


.MuiPaper-root{
    // @media screen and (max-width : 992px) {
    //     left: 10px !important;
    // } 
}

.user-profile-label{
    @media screen and (max-width : 992px) {
        .MuiCardHeader-root{
            padding: 9px;
            width: 50%;
        }
        .MuiCardHeader-content{
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
        .left-margin{
            margin-left: 5px;
        }
    }
}